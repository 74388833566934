import React from 'react';
import clsx from 'clsx';
import {Helmet} from 'react-helmet';
import {Link, Switch, Route, useLocation, Redirect} from 'react-router-dom';
import useFetch from 'fetch-suspense';

import j2r from 'json2react';
import Nav from '../Nav';
import LoadingAnim from '../LoadingAnim';
import Contents from '../Contents';
import {scrollToElem} from '../ScrollButton';

import {useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Hidden, AppBar, Toolbar} from '@material-ui/core';

import mapper from '../Mapper';

// Data fetching/promising

/**
 * Fetch planet info from database
 *
 * @param {object} props properties
 * @return {object} JSX as JSON
 */
function PlanetInfoFetcher(props) {
  const response = useFetch('/v0/planets/'+props.name, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  // Use theme and breakpoint for element scrolling
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  // Scroll to fragment *when fragment has been loaded*
  React.useEffect(() => {
    scrollToElem(props.fragment, xs);
  }, [props.fragment, xs]);

  if (response) {
    // Set up ToC links when data has loaded and elements are known
    const newLinks = [];
    response.information.forEach((elem) => {
      // Only add elements with an id that are explicitly included in the ToC
      if (elem.props?.id && elem.props?.intoc === 'true') {
        newLinks.push({
          name: elem.props.id,
          ref: '#' + elem.props.id,
        });
      }
    });

    // Only render data if response retrieved
    return (
      <div className={clsx(props.compress)}>
        <Hidden xsDown>
          <h2>{response.name}</h2>
        </Hidden>
        <div>{
          response.information?.map((elem, i) => {
            elem.props = elem.props ? {...elem.props, key: i} : {key: i};
            return j2r(React.createElement, mapper, elem);
          })}
        </div>
        <Hidden xsDown>
          <Contents
            links={newLinks}
            drawerOpen={props.drawerOpen}
            setDrawerOpen={props.setDrawerOpen}
          />
        </Hidden>
        <Hidden smUp>
          <AppBar id='xsbar' className={props.classes.xsBar}>
            <Toolbar>
              <Contents
                links={newLinks}
                drawerOpen={props.drawerOpen}
                setDrawerOpen={props.setDrawerOpen}
              />
              <h2
                style={{
                  textAlign: 'center',
                  flexGrow: 1,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                {response.name}
              </h2>
            </Toolbar>
          </AppBar>
        </Hidden>
      </div>
    );
  }

  // No data retrieved, redirect as fallback.
  return (
    <Redirect to='/vastestsea/planets'/>
  );
}

/**
 * Planets page
 *
 * @param {object} props the page properties
 * @return {object} JSX
 */
function Planets(props) {
  const classes = props.classes;
  const [planet, setPlanet] = React.useState({});
  const [planets, setPlanets] = React.useState([]);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const location = useLocation();
  const page =
    location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  // Nav Linking
  const navLinks = [
    {name: 'Main Page', ref: '/vastestsea', to: false},
  ];
  if (page !== 'planets') {
    navLinks.push({name: 'Planets', ref: '/vastestsea/planets', to: false});
  }

  // ToC Linking
  const tocLinks = React.useRef([]);

  // Load planet descriptions from database
  React.useEffect(() => {
    if (page !== 'planets') return;
    const fetchDescriptions = () => {
      fetch('/v0/planets', {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => {
          if (!res.ok) throw res;
          return res.json();
        })
        .then((json) => {
          setPlanets(json);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchDescriptions();
  }, [page]);

  // Scroll to elements
  React.useEffect(() => {
    if (location.hash && location.hash !== '') {
      document.getElementById(location.hash.substring([1]))?.scrollIntoView();
    }
  }, [location]);

  // Helper utility for updating the currently loaded planet.
  const updatePlanet = async (newPlanet) => {
    fetch('/v0/planets/'+newPlanet, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => {
        if (!res.ok) throw res;
        return res.json();
      })
      .then((json) => {
        new Promise((res, rej) => {
          const newLinks = [];
          json.information?.forEach((elem) => {
            if (elem.props?.id) {
              newLinks.push({
                name: elem.props.id,
                ref: '#' + elem.props.id,
              });
            }
          });
          tocLinks.current = [...newLinks];
          // console.log(tocLinks.current);
        });
        setPlanet(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Force planet update if loaded from URL
  React.useEffect(() => {
    if (page !== 'planets' && page !== planet.name?.toLowerCase()) {
      updatePlanet(page);
    }
  }, [page, planet.name]);

  return (
    <div className={classes.flexDesktop}>
      <Helmet>
        <title>The Vastest Sea - Planets</title>
      </Helmet>
      {
        xs &&
        page !== 'planets' ?
          <Toolbar/> : null}
      <h1 className={classes.titleText}>
        {'~@ The Vastest Sea ~@'}
      </h1>
      <Nav
        classes={classes}
        links={navLinks}
      />
      <div className={classes.flexLeft}>
        <Switch>
          <Route exact path='/vastestsea/planets'>
            <h2>Planets</h2>
            <h3
              className={clsx(classes.bold, classes.italic)}
            >
              Orbs Teeming With Life
            </h3>
            <p>
              While the Vastest Sea can best be characterized by its expansive
              emptiness, it is as important to note the density and abundance
              of life on the rocky planets that orbit its stars. Each planet
              has its own stories to discover.
            </p>
            <div
              id='planets'
              className={
                clsx(classes.flexDesktop, classes.fullWidth)
              }
            >
              {planets.map((planet) => {
                return (
                  <div
                    key={planet.name}
                    className={
                      clsx(
                        classes.flexDesktop,
                        classes.fullWidth,
                        classes.flexLeft,
                      )
                    }
                  >
                    <h3
                      className={
                        clsx(
                          classes.bold,
                          classes.italic,
                          classes.header,
                        )
                      }
                    >
                      {planet?.name}
                    </h3>
                    <p>
                      {planet.description ?
                        planet.description.map((elem, i) => {
                          elem.props = elem.props ?
                            {...elem.props, key: i} : {key: i};
                          return j2r(React.createElement, mapper, elem);
                        }) : ''}
                    </p>
                    <Link
                      className={classes.learnMore}
                      onClick={() => updatePlanet(planet.name.toLowerCase())}
                      to={'/vastestsea/planets/' + planet.name.toLowerCase()}
                    >
                      Learn more.
                    </Link>
                  </div>
                );
              })}
            </div>
          </Route>
          <Route>
            <React.Suspense fallback={<LoadingAnim/>}>
              <PlanetInfoFetcher
                name={page}
                fragment={location.hash.substring(1)}
                classes={ classes}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
              />
            </React.Suspense>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default Planets;
