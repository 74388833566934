import React from 'react';

import {Link} from 'react-router-dom';
import {Heading} from './ReactLoadComponents';

/**
 * Entry for the portfolio on the main page
 *
 * @param {object} props the entry properties
 * @return {object} JSX
 */
function PortfolioEntry(props) {
  return (
    <div
      id={props.name.toLowerCase()}
    >
      <Heading>{props.name}</Heading>
      <p>{props.description}</p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {props.published ?
          /^https?:/.test(props.published) ?
            <a
              href={props.published}
              target="_blank"
              rel="noreferrer"
            >View</a> :
            <Link to={props.published}>View</Link> : null
        }
        {props.source ?
          <a
            href={props.source}
            target="_blank"
            rel="noreferrer"
          >Source</a> : null
        }
      </div>
    </div>
  );
}

export default PortfolioEntry;
