import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  flexDesktop: {
    width: '90%',
    display: 'flex',
    margin: '0 auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
    },
  },
  flexTight: {
    width: '80%',
  },
  flexMin: {
    width: 'fit-content',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexLeft: {
    alignItems: 'flex-start',
  },
  link: {
    'color': 'black',
    'textDecoration': 'none',
    'fontWeight': 'bold',
    '&:hover': {
      color: 'white',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
  },
  header: {
    marginBottom: 0,
  },
  learnMore: {
    marginBottom: '2em',
  },
  tocMenu: {
    position: 'fixed',
    top: '0%',
    left: '0%',
  },
  tocDrawer: {
    paddingTop: 40,
  },
  tocDrawerPaper: {
    marginTop: 60,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 10,
    border: 'solid black 1px',
    background: 'rgba(0, 0, 0, 0.45)',
  },
  fitToc: {
    marginRight: 0,
    marginLeft: 'auto',
    width: 'calc(100% - 130px)',
  },
  center: {
    textAlign: 'center',
  },
  titleText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '5.9vw',
    },
  },
  xsBar: {
    '&#xsbar': {
      color: 'white',
      borderBottom: '1.5px solid #00cccc',
      background: 'rgba(0, 0, 0, 0.7)',
      height: 60,
    },
  },
  langBubble: {
    width: '10em',
    textAlign: 'center',
    height: '3em',
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    borderRadius: 10,
    border: '1.5px solid #00cccc',
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
  },
  langLink: {
    color: 'inherit',
    width: '100%',
    textAlign: 'center',
  },
}));

export default useStyles;
