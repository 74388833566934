import React from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {Drawer, Hidden, IconButton, useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import {Glyph} from './Glyph';
import {scrollToElem} from './ScrollButton';

// Style specific to table of contents elements
const useStyle = makeStyles((theme) => ({
  tocMenu: {
    position: 'fixed',
    top: '0%',
    left: '0%',
    [theme.breakpoints.down('xs')]: {
      color: '#00cccc',
    },
  },
  tocDrawer: {
    position: 'fixed',
    paddingTop: 40,
    zIndex: theme.zIndex.appBar - 1,
  },
  tocDrawerPaper: {
    marginTop: 60,
    width: 140,
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 10,
    marginLeft: -10,
    borderRadius: 10,
    border: 'solid #00cccc 1.5px',
    background: 'rgba(0, 0, 0, 0.7)',
    height: 'calc(100% - 60px)',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      paddingLeft: 30,
      marginTop: 68,
      marginLeft: '0 auto',
      height: 'fit-content',
      maxHeight: 'calc(100% - 60px)',
      left: -10,
      top: -10,
    },
  },
  tocDrawerMax: {
    paddingTop: 0,
  },
  tocDrawerPaperMax: {
    marginTop: -10,
    paddingTop: 20,
    height: '100%',
  },
  tocSectionLabel: {
    color: 'white',
    fontWeight: 'bold',
    marginTop: 0,
  },
  tocEntry: {
    'borderBottom': '1.5px solid #00cccc',
  },
  tocContainer: {
    'marginTop': -10,
    'marginBottom': 10,
    'paddingBottom': 10,
    'paddingTop': 10,
    'overflowY': 'scroll',
    'scrollbarWidth': 'none',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '& div a': {
      'color': 'white',
      '&:hover': {
        'color': '#00cccc',
      },
    },
  },
  hide: {
    display: 'none',
  },
}));

/**
 * Table of Contents Element
 *
 * @param {object} props the table properties
 * @return {object} JSX
 */
function Contents(props) {
  const classes = useStyle();
  const theme = useTheme();
  const {drawerOpen, setDrawerOpen} = props;

  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const max = useMediaQuery(theme.breakpoints.up('lg'));

  // Update drawer based on several factors, including screen size and links
  React.useEffect(() => {
    if (props.links?.length < 1 || !setDrawerOpen) return;
    if ((md || max) && props.links?.length > 0) setDrawerOpen(true);
    else setDrawerOpen(false);
  }, [md, max, setDrawerOpen, props.links?.length]);

  // Don't render anything if there are no links/callback
  if (props.links?.length < 1 || !setDrawerOpen) return null;

  // Drawer open handler for basic callback
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  // Drawer close handler for callback, ignoring calls to close on lg or xl
  const handleDrawerClose = () => {
    if (!max) setDrawerOpen(false);
  };

  // Handle link clicks
  const onContentClick = (ref) => {
    if (drawerOpen && !xs) {
      handleDrawerClose();
      setTimeout(() => scrollToElem(ref, xs), 100);
    } else if (drawerOpen) {
      handleDrawerClose();
      scrollToElem(ref, xs);
    } else {
      scrollToElem(ref, xs);
    }
  };

  // Handle menu button click
  const onMenuClick = () => {
    if (drawerOpen) handleDrawerClose();
    else handleDrawerOpen();
  };

  // Initialize ToC elements list with Contents label
  let list =
    [(<h3 key='c' className={classes.tocSectionLabel}>Contents</h3>)];

  // Add all link data to element list by converting to React components
  list = list.concat(props.links.map((link) => {
    return (
      <div
        key={'toc_'+link.name}
        style={{borderBottom: '1.5px solid #00cccc'}}
      >
        <Link
          onClick={() => onContentClick(link.name)}
          to={link.ref}
        >
          {link.name}
          {link.language ?
            <React.Fragment>
              <span>{' - '}</span>
              <Glyph lexentry language={link.language}>{link.glyph}</Glyph>
            </React.Fragment> : null
          }
        </Link>
      </div>
    );
  }));

  // Only add a Related section if the data exists
  if (props.related) {
    list.push((
      <h3
        key='r'
        className={classes.tocSectionLabel}
        style={{marginTop: 40}}
      >
        Related
      </h3>
    ));

    list = list.concat(props.related.map((link) => {
      return (
        <div key={'toc_'+link.name} className={classes.tocEntry}>
          <Link
            to={link.ref}
          >
            {link.name}
          </Link>
        </div>
      );
    }));
  }

  // Secondary check to ensure that rendering only occurs if list nonempty
  if (list.length > 0) {
    return (
      <React.Fragment>
        <Hidden lgUp>
          <IconButton
            onClick={onMenuClick}
            className={classes.tocMenu}
          >
            <MenuIcon fontSize="large"/>
          </IconButton>
        </Hidden>
        <Drawer
          variant="persistent"
          open={drawerOpen}
          className={clsx(classes.tocDrawer, max && classes.tocDrawerMax)}
          classes={{
            paper: clsx(classes.tocDrawerPaper,
              max && classes.tocDrawerPaperMax),
          }}
          anchor={xs ? 'top' : 'left'}
          elevation={1}
        >
          <div
            className={classes.tocContainer}
          >
            {list}
          </div>
        </Drawer>
      </React.Fragment>
    );
  } else {
    // Don't render anything with an empty list
    return null;
  }
}

export default Contents;
