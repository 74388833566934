import React from 'react';
import clsx from 'clsx';
import {useScrollTrigger, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// Fade in based on solution from Giladd on StackOverflow
// https://stackoverflow.com/a/41853499
const useStyle = makeStyles((theme) => ({
  button: {
    borderRadius: '50%',
    textALign: 'center',
    position: 'fixed',
    bottom: 20,
    right: 20,
    background: 'rgba(0, 0, 0, 0.7)',
    border: '1.5px solid #00cccc',
    opacity: 1,
    transition: 'opacity 0.5s ease-in-out',
  },
  hide: {
    opacity: 0,
    pointerEvents: 'none',
  },
}));

/**
 * Scroll Button
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
function ScrollButton(props) {
  const classes = useStyle();
  const trigger = useScrollTrigger({disableHysteresis: true, threshold: 500});

  return (
    <div
      className={clsx(classes.button, !trigger && classes.hide)}
    >
      <IconButton
        elevation={1}
        onClick={() => document.getElementById('root').scrollIntoView()}
      >
        <KeyboardArrowUpIcon style={{color: '#00cccc'}} fontSize='large'/>
      </IconButton>
    </div>
  );
}

export default ScrollButton;

/**
 * Scroll to element function
 *
 * @param {string} id the element id
 * @param {boolean} xs whether to factor in the xsbar
 */
export function scrollToElem(id, xs) {
  const elem = document.getElementById(id);
  if (!elem) return;

  let pos = elem.getBoundingClientRect().top;

  if (xs) {
    pos -= 70;
  }

  window.scrollBy({
    top: pos,
    behavior: 'smooth',
  });
}
