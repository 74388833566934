import React from 'react';
import {ReactInterval as Interval} from 'react-interval';

import {Glyph} from './Glyph';

// Use fjorunskara characters that render more centrally for best results
const characters = [
  'b', 'd', 't', 'g', 'k', 'c', 'z', 's', 'sh', 'm', 'n', 'v', 'f',
  'x', 'h',
];

// Latest selection
let latest = -1;

/**
 * Loading Animation for DB suspense
 *
 * @return {object} JSX
 */
function LoadingAnim() {
  const [char, setChar] = React.useState(0);

  /**
   * Character selection
   *
   * @param {int} avoid index to avoid
   * @return {object} next character
   */
  const chooseChar = () => {
    let idx = latest;
    while (idx === latest) {
      idx = Math.floor(Math.random() * characters.length);
    }
    latest = idx;
    return idx;
  };

  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: '2px solid black',
          borderRadius: 10,
          paddingLeft: 5,
          paddingRight: 5,
          background: 'rgba(0, 0, 0, 0.45)',
          color: 'white',
        }}
      >
        <h3
          style={{
            marginTop: 0,
            textAlign: 'center',
          }}
        >
          Loading...
        </h3>
        <h1
          style={{
            margin: 0,
            fontSize: '300%',
            textAlign: 'center',
          }}
        >
          <Interval
            enabled
            callback={
              () => setChar(chooseChar())
            }
          />
          <Glyph language='fjorunskara'>{characters[char]}</Glyph>
        </h1>
      </div>
    </div>
  );
}

export default LoadingAnim;
