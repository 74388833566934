import React from 'react';
import {Router, Switch, Route}
  from 'react-router-dom';
// import {ScrollManager} from 'react-scroll-manager';
import {createBrowserHistory as createHistory} from 'history';

import useStyle from './Styles';
import Landing from './Landing';
import VastestSea from './vastestsea/VastestSea';
import Planets from './vastestsea/Planets';
import Deities from './vastestsea/Deities';
import Languages from './vastestsea/Languages';

import ScrollButton from './ScrollButton';
import {WordBubble} from './Glyph';

/**
 * Main application
 *
 * @return {object} JSX
 */
function App() {
  const classes = useStyle();
  const history = createHistory();

  return (
    <Router history={history}>
      <WordBubble/>
      <Switch>
        <Route path="/vastestsea">
          <ScrollButton/>
          <Switch>
            <Route path="/vastestsea/planets">
              <Planets classes={classes}/>
            </Route>
            <Route path="/vastestsea/deities">
              <Deities classes={classes}/>
            </Route>
            <Route path="/vastestsea/languages">
              <Languages classes={classes}/>
            </Route>
            <Route>
              <VastestSea classes={classes}/>
            </Route>
          </Switch>
        </Route>
        <Route path="/">
          <Landing classes={classes}/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
