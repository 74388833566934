// Utility components for better code reuse.
// Effectively defines the schema for some stored pages

import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';


// Styles used by these components
const useStyle = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  center: {
    textAlign: 'center',
  },
  spoiler: {
    'backgroundColor': 'black',
    'color': 'black',
    'userSelect': 'none',
    '&::selection': {
      'color': 'black',
    },
    '& *': {
      'pointerEvents': 'none',
      '&::selection': {
        'color': 'black',
      },
    },
  },
}));

/**
 * Heading for loading from db
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function Heading(props) {
  const classes = useStyle();
  return (
    <h3
      id={props.id}
      intoc={props.intoc}
      className={clsx(classes.bold, classes.italic)}
    >
      {props.children}
    </h3>
  );
}

/**
 * Subheading for loading from db
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function Subheading(props) {
  const classes = useStyle();
  return (
    <p
      id={props.id}
      intoc={props.intoc}
      className={clsx(classes.bold, classes.italic)}
    >
      {props.children}
    </p>
  );
}

/**
 * Spoiler content from db
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function Spoiler(props) {
  const classes = useStyle();
  const [visible, setVisible] = React.useState(false);

  return (
    <span
      className={clsx(!visible && classes.spoiler)}
      onClick={() => setVisible(!visible)}
    >
      {props.children}
    </span>
  );
}

/**
 * Bold text
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function Bold(props) {
  const classes = useStyle();

  return (<span className={classes.bold}>{props.children}</span>);
}

/**
 * Italicized text
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function Italics(props) {
  const classes = useStyle();

  return (<span className={classes.italic}>{props.children}</span>);
}

/**
 * Centered text
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function Centered(props) {
  const classes = useStyle();

  return (<p className={classes.center}>{props.children}</p>);
}
