/**
 * Language-related React Components
 * Most of these are stateless and purely exist for data to DOM conversion.
 */

import React from 'react';
// import useFetch from 'fetch-suspense';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery} from '@material-ui/core';
import LoadingAnim from './LoadingAnim';
import clsx from 'clsx';

// Language-component styles
const useStyle = makeStyles((theme) => ({
  graphemeHeader: {
    fontWeight: 'bold',
    marginBottom: 0,
  },
  graphemeText: {
    marginTop: 0,
  },
  fjorunskara: {
    fontFamily: 'Fjorunskara',
    fontSize: '140%',
  },
  tableTitle: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    marginBottom: 0,
    textAlign: 'center',
  },
  columnHeader: {
    fontWeight: 'bold',
    minWidth: '7em',
  },
  columnRightmost: {
    minWidth: 'calc(100% - 14em)',
  },
  doubleColumn: {
    width: '50%',
  },
  table: {
    width: '100%',
  },
  lexTable: {
    display: 'grid',
    margin: '0 auto',
    width: '100%',
    gridColumnGap: 5,
    gridRowGap: 20,
    listStyleType: 'none',
    gridTemplateColumns: '25% 25% 25% 25%',
    gridAutoFlow: 'row',
  },
  lexTableMid: {
    gridTemplateColumns: '33% 33% 33%',
  },
  lexTableCompact: {
    gridTemplateColumns: '50% 50%',
  },
  lexTableMinimal: {
    gridTemplateColumns: '100%',
  },
  lexFragment: {
    marginTop: 0,
    marginBottom: 0,
  },
  wordBubble: {
    border: '1.5px solid #00cccc',
    borderRadius: 10,
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    position: 'fixed',
    maxHeight: '50vh',
    maxWidth: '75vw',
    padding: 10,
    top: 20,
    right: 20,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      top: 70,
      right: 10,
    },
  },
  hide: {
    display: 'none',
  },
  glyphHighlight: {
    '&:focus': {
      background: 'rgba(0, 0, 0, 0.7)',
      color: 'white',
      border: '1.5px solid #00cccc',
      borderRadius: 5,
      padding: 2,
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.7)',
      color: 'white',
      border: '1.5px solid #00cccc',
      borderRadius: 5,
      padding: 2,
    },
  },
}));

// Temporary languages dictionary for Stylization lookup
// TODO: Refactor DB and add API endpoint for style retrieval
const languages = {fjorunskara: 'Fjorunskara'};

// Remove punctuation from text for lookup
const depunctuate = (text, language) => {
  let delim;
  switch (language) {
  case 'fjorunskara':
    delim = '-';
    break;
  default:
    delim= '';
    break;
  }

  try {
    return text.split(delim).join('');
  } catch (err) {
    console.error(err);
    return text;
  }
};

/**
 * Glyph rendering wrapper
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function Glyph(props) {
  const classes = useStyle();

  let language;
  switch (props.language?.toLowerCase()) {
  case 'fjorunskara':
    language = classes.fjorunskara;
    break;
  default:
    language = undefined;
  }

  // Handle Quick Look bubble creation
  const quickLookStart = () => {
    if (props.lexentry) return;
    WordBubble.update({
      display: true,
      word: depunctuate(
        props.children,
        props.language.toLowerCase(),
      ),
      language: props.language,
    });
  };

  // Handle Quick Look bubble removal
  const quickLookStop = () => {
    WordBubble.update({
      display: false,
      word: undefined,
      language: undefined,
    });
  };

  return (
    <React.Fragment>{props.sentence ?
      <span>
        {props.children.split(' ').map((word, i) => {
          return (
            <Glyph key={i} language={props.language}>
              {word + ' '}
            </Glyph>
          );
        })}
      </span> :
      <span
        onMouseEnter={quickLookStart}
        onFocus={quickLookStart}
        onMouseLeave={quickLookStop}
        onBlur={quickLookStop}
        style={{fontStyle: 'normal'}}
        className={clsx(language, !props.lexentry && classes.glyphHighlight)}
        tabIndex={0}
      >
        {props.children}
      </span>
    }</React.Fragment>
  );
}

/**
 * Grapheme entry for language inventories
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function Grapheme(props) {
  const classes = useStyle();

  return (
    <div id={props.character.toUpperCase()}>
      <p className={classes.graphemeHeader}>
        {props.hasUpperCase && props.character.toUpperCase() + '/'}
        {props.character.toLowerCase()}
        &nbsp;-&nbsp;
        <Glyph lexentry language={props.language}>
          {props.hasUpperCase && props.character.toUpperCase() + ' '}
          {props.character.toLowerCase()}
        </Glyph>
      </p>
      <p className={classes.graphemeText}>
        Pronounced /{props.transcription}/, as in the English "
        {props.equivalent}."<br/>E.g. {props.exampleRoman} -&nbsp;
        <Glyph language={props.language}>
          {props.exampleGlyph}
        </Glyph>
      </p>
    </div>
  );
}

/**
 * Phontactic set entry
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function PhonoSet(props) {
  return (
    <React.Fragment>
      {props.setname} = {props.setdesc}<br/>
    </React.Fragment>
  );
}

/**
 * Phonotactic rule entry
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function PhonoRule(props) {
  return (
    <tr>
      <td>{props.struct}</td>
      <td>{props.desc}</td>
    </tr>
  );
}

/**
 * Phonotactic rule table
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function PhonoTable(props) {
  const classes = useStyle();

  return (
    <React.Fragment>
      <p className={classes.tableTitle}>
        Accepted Syllables
      </p>
      <table className={classes.table}>
        <thead>
          <tr/>
          <tr>
            <td className={classes.columnHeader}>Structure</td>
            <td
              className={
                clsx(classes.columnHeader, classes.columnRightmost)
              }
            >
              Meaning
            </td>
          </tr>
        </thead>
        <tbody>
          {props.children}
        </tbody>
      </table>
    </React.Fragment>
  );
}

/**
 * Extra phonotactic rules
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function ExtraPhonoRules(props) {
  let ruleNumber = 0;

  return (
    <p>
      Extra phonotactic and phonetic rules that cross syllable boundaries are
      as follows:
      <br/>
      <br/>
      {props.children.map((child) => {
        return (
          <React.Fragment key={'rule'+ruleNumber}>
            <span>{(++ruleNumber) + ') '}{child}</span><br/>
          </React.Fragment>
        );
      })}
    </p>
  );
}

/**
 * Morpheme table
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function MorphemeTable(props) {
  const classes = useStyle();

  return (
    <React.Fragment>
      <p
        id={props.id}
        className={classes.tableTitle}
      >
        Bound Morphemes
      </p>
      <table className={classes.table}>
        <thead>
          <tr/>
          <tr>
            <td className={classes.columnHeader}>
              {'Morpheme'}
            </td>
            <td className={classes.columnHeader}>
              Type
            </td>
            <td
              className={
                clsx(classes.columnHeader, classes.columnRightmost)
              }
            >
              Meaning
            </td>
          </tr>
        </thead>
        <tbody>
          {props.children}
        </tbody>
      </table>
    </React.Fragment>
  );
}

/**
 * Bound Morpheme
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function Morpheme(props) {
  return (
    <tr id={props.id}>
      <td>
        {props.roman + ' '}
        <Glyph language={props.language}>
          {props.glyph}
        </Glyph>
      </td>
      <td>
        {props.type}
      </td>
      <td>
        {props.desc}
      </td>
    </tr>
  );
}

/**
 * Marker Table
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function MarkerTable(props) {
  const classes = useStyle();

  return (
    <React.Fragment>
      <p className={classes.tableTitle}>{props.name} Markers</p>
      <table className={classes.table}>
        <thead>
          <tr/>
          <tr>
            <td className={clsx(classes.columnHeader, classes.doubleColumn)}>
              Marker
            </td>
            <td className={clsx(classes.columnHeader, classes.doubleColumn)}>
              {props.name}
            </td>
          </tr>
        </thead>
        <tbody>
          {props.children.map((c, i) => {
            const uProps = c.props ? {...c.props, language: props.language} :
              {language: props.language};
            const updated = {...c, props: uProps};
            return updated;
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
}

/**
 * Marker
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function Marker(props) {
  return (
    <tr>
      <td>
        {props.roman + ' '}
        <Glyph language={props.language}>{props.glyph}</Glyph>
      </td>
      <td>
        {props.meaning}
      </td>
    </tr>
  );
}

/**
 * Dual-column table
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function DoubleTable(props) {
  const classes = useStyle();

  return (
    <React.Fragment>
      <p className={classes.tableTitle}>{props.name}</p>
      <table className={classes.table}>
        <thead>
          <tr/>
          <tr>
            <td className={clsx(classes.columnHeader, classes.doubleColumn)}>
              {props.left}
            </td>
            <td className={clsx(classes.columnHeader, classes.doubleColumn)}>
              {props.right}
            </td>
          </tr>
        </thead>
        <tbody>
          {props.children.map((c, i) => {
            const uProps = c.props ? {...c.props, language: props.language} :
              {language: props.language};
            const updated = {...c, props: uProps};
            return updated;
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
}

/**
 * Double Table Entry
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function DoubleEntry(props) {
  return (
    <tr>
      <td>
        {props.left.roman + ' '}
        <Glyph language={props.language}>{props.left.glyph}</Glyph>
      </td>
      <td>
        {props.right.roman + ' '}
        <Glyph language={props.language}>{props.right.glyph}</Glyph>
      </td>
    </tr>
  );
}

/**
 * Example sentence
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function Example(props) {
  return (
    <p>
      {props.roman}
      <br/>
      <Glyph sentence language={props.language}>
        {props.glyph}
      </Glyph>
      <br/>
      {props.translation}
    </p>
  );
}

/**
 * Lexical Section
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
export function LexSection(props) {
  const {section} = props;
  const classes = useStyle();
  const theme = useTheme();

  const md = useMediaQuery(theme.breakpoints.down('md'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div
      id={section.start.toUpperCase()}
      intoc='true'
      style={{textAlign: 'center'}}
    >
      <h2>
        {section.start.toUpperCase() + ' '}
        <Glyph lexentry language={props.language}>{section.values.glyph}</Glyph>
      </h2>
      <div className={
        clsx(
          classes.lexTable,
          md && classes.lexTableMid,
          sm && classes.lexTableCompact,
          xs && classes.lexTableMinimal,
        )
      }>
        {section.values.words.map((word) => {
          return (
            <LexEntry
              id={word.name_roman}
              key={word.name_roman}
              word={word}
              language={props.language}
            />
          );
        })}
      </div>
      <br/><br/>
    </div>
  );
}

/**
 * Lexical Entry
 *
 * @param {object} props the properties.
 * @return {object} JSX
 */
export function LexEntry(props) {
  const {word} = props;
  const classes = useStyle();

  return (
    <div id={props.id}>
      <p className={classes.lexFragment}>
        <span style={{fontWeight: 'bold'}}>{word.name_roman + ' - '}</span>
        <Glyph lexentry language={props.language}>{word.name_glyph}</Glyph>
      </p>
      <p
        className={classes.lexFragment}
        style={{fontStyle: 'italic'}}
      >
        {word.part}
      </p>
      <p className={classes.lexFragment}>{word.definition}</p>
    </div>
  );
}

// Get information for Quick Look bubble
const BubbleInfo = (props) => {
  const classes = useStyle();
  const [word, setWord] = React.useState({});

  // Fetch new data on language or word update
  React.useEffect(() => {
    fetch('http://localhost:3010/v0/languages/' +
      props.language.toLowerCase() + '/lexicon/' + props.word, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => {
        if (!res.ok) throw res;
        return res.json();
      })
      .then((json) => {
        setWord(json);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.language, props.word]);

  // Boolean flag to check that there is, in fact, a word in the bubble
  const hasWord = word &&
    Object.keys(word).length !== 0 &&
    word.constructor === Object;

  return (
    <React.Fragment>
      <div
        className={
          clsx(
            classes.wordBubble,
            (props.hidden || !hasWord || !props.language) && classes.hide,
          )
        }
      >
        <div
          style={{borderRight: '1.5px solid #00cccc', paddingRight: 10}}
        >
          <h3 style={{marginTop: 0, marginBottom: 'auto'}}>
            Quick Look
          </h3>
          <p
            style={{
              marginTop: 'auto',
              marginBottom: 0,
            }}
          >
            {languages[props.language]}
          </p>
        </div>
        <div style={{paddingLeft: 10}}>
          <LexEntry
            id={'bubble'}
            language={props.language}
            word={word}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

/**
 * Word Bubble for displaying definitions out of Lexicon
 *
 * @param {object} props the properties.
 * @return {object} JSX
 */
export function WordBubble(props) {
  const [values, setValues] = React.useState({
    display: false,
    word: '',
    language: '',
  });

  // Expose update method to respond to Glyph QL handlers
  const update = (vals) => {
    setValues(vals);
  };
  WordBubble.update = update;

  return (
    <div id={'bubble'}>
      {values.word && values.language ?
        <React.Suspense fallback={<LoadingAnim/>}>
          <BubbleInfo
            display={values.display}
            word={values.word}
            language={values.language}
          />
        </React.Suspense> : null
      }
    </div>
  );
}
