import React from 'react';
import clsx from 'clsx';
import {Link, Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import j2r from 'json2react';
import useFetch from 'fetch-suspense';
import {useMediaQuery, Hidden, AppBar, Toolbar} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';

import LoadingAnim from '../LoadingAnim';
import Nav from '../Nav';
import Contents from '../Contents';
import {Glyph} from '../Glyph';
import {scrollToElem} from '../ScrollButton';

import mapper from '../Mapper';

const langPrinting = {'fjorunskara': 'Fjorunskara'};

// Data fetching/promising

/**
 * Fetch language info from database
 *
 * @param {object} props properties
 * @return {object} JSX as JSON
 */
function LangInfoFetcher(props) {
  let lang = props.name.substring(0, props.name.indexOf('/'));
  const subPage = props.name.substring(props.name.lastIndexOf('/') + 1);

  if (props.name.indexOf('/') === -1) {
    lang = subPage;
  }

  const response = useFetch('/v0/languages/' +
    lang.toLowerCase(), {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    if (props.fragment) {
      scrollToElem(props.fragment, xs);
    }
  }, [props.fragment, xs]);

  if (response) {
    let info;
    switch (subPage) {
    case 'inventory_orthography':
      info = response.inv_orth_info;
      break;
    case 'syntax_structure':
      info = response.syntax_info;
      break;
    case lang:
      info = response.main_info;
      break;
    default:
      return <Redirect to={`/vastestsea/languages/${lang}`}/>;
    }

    const newLinks = [];
    info.forEach((elem) => {
      if (elem.props?.id && elem.props?.intoc === 'true') {
        newLinks.push({
          name: elem.props.id,
          ref: '#' + elem.props.id,
        });
      }
    });

    const relatedLinks = [
      subPage === 'inventory_orthography' ?
        {
          name: 'Syntax & Structure',
          ref: './syntax_structure',
        } :
        {
          name: 'Inventory & Orthography',
          ref: './inventory_orthography',
        },
      {
        name: 'Lexicon',
        ref: './lexicon',
      },
    ];

    return (
      <div>
        <Hidden xsDown>
          <h2 className={props.classes.center}>
            {response.name_roman} -&nbsp;
            <Glyph language={response.name_roman}>{response.name_glyph}</Glyph>
            {
              subPage && <br/>
            }
            {
              subPage === 'inventory_orthography' ? 'Inventory & Orthography' :
                subPage === 'syntax_structure' ? 'Syntax & Structure' :
                  subPage === 'lexicon' ? 'Lexicon' :
                    null
            }
          </h2>
        </Hidden>
        <div>{

          info?.map((elem, i) => {
            elem.props = elem.props ? {...elem.props, key: i} : {key: i};
            return j2r(React.createElement, mapper, elem);
          })}
        </div>
        {
          subPage === lang ?
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div className={props.classes.langBubble}>
                <Link
                  to={`/vastestsea/languages/${lang}` +
                    `/inventory_orthography`}
                  className={props.classes.langLink}
                >
                  Inventory &amp; Orthography
                </Link>
              </div>
              <div className={props.classes.langBubble}>
                <Link
                  to={`/vastestsea/languages/${lang}` +
                    `/syntax_structure`}
                  className={props.classes.langLink}
                >
                  Syntax &amp; Structure
                </Link>
              </div>
              <div className={props.classes.langBubble}>
                <Link
                  to={`/vastestsea/languages/${lang}` +
                    `/lexicon`}
                  className={props.classes.langLink}
                >
                  Lexicon
                </Link>
              </div>
            </div> :
            <React.Fragment>
              <Hidden xsDown>
                <Contents
                  links={newLinks}
                  related={relatedLinks}
                  drawerOpen={props.drawerOpen}
                  setDrawerOpen={props.setDrawerOpen}
                />
              </Hidden>
              <Hidden smUp>
                <AppBar id='xsbar' className={props.classes.xsBar}>
                  <Toolbar>
                    <Contents
                      links={newLinks}
                      related={relatedLinks}
                      drawerOpen={props.drawerOpen}
                      setDrawerOpen={props.setDrawerOpen}
                    />
                    <h2
                      style={{
                        textAlign: 'center',
                        flexGrow: 1,
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      {langPrinting[lang]}
                      {subPage === 'inventory_orthography' ? ' Orthography' :
                        subPage === 'syntax_structure' ? ' Syntax' :
                          subPage === 'lexicon' ? ' Lexicon' : ''}
                    </h2>
                  </Toolbar>
                </AppBar>
              </Hidden>
            </React.Fragment>
        }
      </div>
    );
  }
  return (
    <div className={props.classes.center}>
      <h1>Error</h1>
      <h2>Could not retrieve language:<br/>{lang}</h2>
      <p>
        You seem to have taken the wrong Warpcurrent.<br/>
        Double check that the URL is spelled correctly and try again.<br/><br/>
        <Link to='/vastestsea/languages'>{'<- Languages'}</Link>
      </p>
    </div>
  );
}

/**
 * Fetch lexicon info from database
 *
 * @param {object} props properties
 * @return {object} JSX as JSON
 */
function LexInfoFetcher(props) {
  const lang = props.name.substring(0, props.name.indexOf('/'));

  const response = useFetch('/v0/languages/' +
    lang.toLowerCase(), {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  const lexSections = useFetch('/v0/languages/' +
    lang.toLowerCase() + '/lexicon/sections', {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    scrollToElem(props.fragment, xs);
  }, [props.fragment, xs]);

  let info;
  if (response) {
    info = response.lex_info;
    if (lexSections) {
      info = info.concat(lexSections.sections.map((e) => {
        return {
          type: 'LexSection',
          props: {
            language: lang,
            section: e,
            id: e.start.toUpperCase(),
            intoc: 'true',
          },
        };
      }));
    }

    const newLinks = [];
    info.forEach((elem) => {
      if (elem.props?.id && elem.props?.intoc === 'true') {
        newLinks.push({
          name: elem.props.id,
          ref: '#' + elem.props.id,
          language: lang,
          glyph: elem.props.section.values['glyph'],
        });
      }
    });

    const relatedLinks = [
      {
        name: 'Inventory & Orthography',
        ref: './inventory_orthography',
      },
      {
        name: 'Syntax & Structure',
        ref: './syntax_structure',
      },
    ];

    return (
      <div onLoad={props.onLoad}>
        {
          info?.map((elem, i) => {
            elem.props = elem.props ? {...elem.props, key: i} : {key: i};
            return j2r(React.createElement, mapper, elem);
          })
        }
        <Hidden xsDown>
          <Contents
            links={newLinks}
            related={relatedLinks}
            drawerOpen={props.drawerOpen}
            setDrawerOpen={props.setDrawerOpen}
          />
        </Hidden>
        <Hidden smUp>
          <AppBar id='xsbar' className={props.classes.xsBar}>
            <Toolbar>
              <Contents
                links={newLinks}
                related={relatedLinks}
                drawerOpen={props.drawerOpen}
                setDrawerOpen={props.setDrawerOpen}
              />
              <h2
                style={{
                  textAlign: 'center',
                  flexGrow: 1,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                {langPrinting[lang] + ' Lexicon'}
              </h2>
            </Toolbar>
          </AppBar>
        </Hidden>
      </div>
    );
  }

  return (<div/>);
}


/**
 * Languages page
 *
 * @param {object} props the page properties
 * @return {object} JSX
 */
function Languages(props) {
  const classes = props.classes;
  const [language, setLanguage] = React.useState({});
  const [languages, setLanguages] = React.useState([]);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const location = useLocation();
  const page = location.pathname === '/vastestsea/languages' ?
    'languages' :
    location.pathname.substring(
      location.pathname.lastIndexOf('languages/') + 10,
    );
  const subpage = page.substring(page.lastIndexOf('/') + 1);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  // Nav Linking
  const navLinks = [
    {name: 'Main Page', ref: '/vastestsea', to: false},
  ];
  if (page !== 'languages') {
    navLinks.push({name: 'Languages', ref: '/vastestsea/languages', to: false});
    if (subpage === 'inventory_orthography' ||
      subpage === 'syntax_structure' || subpage === 'lexicon') {
      navLinks.push({name: language?.name_roman, ref: '.', to: false});
    }
  }

  // Load language descriptions from database
  React.useEffect(() => {
    if (page !== 'languages') return;
    const fetchDescriptions = () => {
      fetch('/v0/languages', {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => {
          if (!res.ok) throw res;
          return res.json();
        })
        .then((json) => {
          setLanguages(json);
          setDrawerOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchDescriptions();
  }, [page]);

  // Scroll to elements
  React.useEffect(() => {
    if (location.hash && location.hash !== '') {
      scrollToElem(location.hash.substring([1]), xs);
    } else if (location.hash === '') {
      scrollToElem('root', xs);
    }
  }, [location, xs]);

  const updateLanguage = async (newLanguage) => {
    fetch('/v0/languages/'+newLanguage, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => {
        if (!res.ok) throw res;
        return res.json();
      })
      .then((json) => {
        setLanguage(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Force language update if loaded from URL
  React.useEffect(() => {
    const urlLang = page.substring(0, page.indexOf('/'));
    if (page !== 'languages' &&
      urlLang.toLowerCase() !== language.name_roman?.toLowerCase()) {
      updateLanguage(urlLang.toLowerCase());
    }
  }, [page, language.name_roman]);

  return (
    <div
      className={
        clsx(classes.flexDesktop, drawerOpen && !xs && classes.fitToc)
      }
    >
      <Helmet>
        <title>The Vastest Sea - Languages</title>
      </Helmet>
      {
        xs &&
        page !== 'languages' &&
        (subpage === 'inventory_orthography' ||
          subpage === 'syntax_structure' ||
          subpage === 'lexicon') ?
          <Toolbar/> : null
      }
      <h1 className={classes.titleText}>
        /<Glyph lexentry language='fjorunskara'>b</Glyph>/
        The Vastest Sea
        /<Glyph lexentry language='fjorunskara'>b</Glyph>/
      </h1>
      <Nav
        classes={classes}
        links={navLinks}
      />
      <div
        className={clsx(classes.flexLeft)}
        style={{width: '100%'}}
      >
        <Switch>
          <Route exact path='/vastestsea/languages'>
            <h2>Languages</h2>
            <p
              className={clsx(classes.bold, classes.italic)}
            >
              The Many Tongues of Xarrodddegnon
            </p>
            <p>
              It is generally accepted that Xarrodddegnon is the&nbsp;
              <Link to="/vastestsea/deities/macrocosms">Macrocosm</Link>&nbsp;
              that embodies communication. As a result, all languages in the
              Sea stem from its own ancestral utterances.
            </p>
            <div
              id='languages'
              className={
                clsx(classes.flexDesktop, classes.fullWidth, classes.flexLeft)
              }
            >
              {languages.map((language) => {
                return (
                  <div
                    key={language.name_roman}
                    className={
                      clsx(
                        classes.flexDesktop,
                        classes.fullWidth,
                        classes.flexLeft,
                      )
                    }
                  >
                    <p
                      className={
                        clsx(
                          classes.bold,
                          classes.italic,
                          classes.header,
                        )
                      }
                    >
                      {language.name_roman} -&nbsp;
                      <Glyph language={language.name_roman}>
                        {language.name_glyph}
                      </Glyph>
                    </p>
                    <div>
                      {language.description ?
                        j2r(
                          React.createElement,
                          mapper,
                          language.description,
                        ) : ''
                      }
                    </div>
                    <Link
                      className={classes.learnMore}
                      onClick={
                        () => {
                          updateLanguage(language.name_roman.toLowerCase());
                        }
                      }
                      to={
                        '/vastestsea/languages/' +
                        language.name_roman.toLowerCase()
                      }
                    >
                      Learn more.
                    </Link>
                  </div>
                );
              })}
            </div>
          </Route>
          <Route>
            <React.Suspense fallback={<LoadingAnim/>}>
              {location.pathname.substring(
                location.pathname.lastIndexOf('/') + 1) !== 'lexicon' ?
                <LangInfoFetcher
                  name={page}
                  classes={classes}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                  fragment={
                    location.hash.substring(1)
                  }
                /> :
                <LexInfoFetcher
                  name={page}
                  classes={classes}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                  fragment={
                    location.hash.substring(1)
                  }
                />
              }
            </React.Suspense>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default Languages;
