import {Link} from 'react-router-dom';
import {Glyph, Grapheme, PhonoSet, PhonoRule, PhonoTable, ExtraPhonoRules,
  MorphemeTable, Morpheme, MarkerTable, Marker, DoubleTable, DoubleEntry,
  Example, LexSection, LexEntry}
  from './Glyph';
import {Heading, Subheading, Spoiler, Bold, Italics, Centered}
  from './ReactLoadComponents';

/**
 * Map tag names to React components
 * Necessary for the JSON to React conversion
 *
 * @param {string} type the component tag
 * @param {object} props the component properties
 * @return {object} React component
 */
const mapper = (type, props) => {
  switch (type) {
  case 'Link':
    return Link;
  case 'Glyph':
    return Glyph;
  case 'Grapheme':
    return Grapheme;
  case 'PhonoSet':
    return PhonoSet;
  case 'PhonoRule':
    return PhonoRule;
  case 'PhonoTable':
    return PhonoTable;
  case 'ExtraPhonoRules':
    return ExtraPhonoRules;
  case 'MorphemeTable':
    return MorphemeTable;
  case 'Morpheme':
    return Morpheme;
  case 'MarkerTable':
    return MarkerTable;
  case 'Marker':
    return Marker;
  case 'DoubleTable':
    return DoubleTable;
  case 'DoubleEntry':
    return DoubleEntry;
  case 'Example':
    return Example;
  case 'LexSection':
    return LexSection;
  case 'LexEntry':
    return LexEntry;
  case 'Heading':
    return Heading;
  case 'Subheading':
    return Subheading;
  case 'Spoiler':
    return Spoiler;
  case 'Bold':
    return Bold;
  case 'Italics':
    return Italics;
  case 'Centered':
    return Centered;
  default:
    return type;
  }
};

export default mapper;
