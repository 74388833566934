import React from 'react';
import clsx from 'clsx';
import {Link, Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import j2r from 'json2react';
import useFetch from 'fetch-suspense';
import {useMediaQuery, Hidden, AppBar, Toolbar} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';

import LoadingAnim from '../LoadingAnim';
import Nav from '../Nav';
import Contents from '../Contents';
import {scrollToElem} from '../ScrollButton';

import mapper from '../Mapper';

// Data fetching/promising

/**
 * Fetch deity type info from database
 *
 * @param {object} props properties
 * @return {object} JSX as JSON
 */
function TypeInfoFetcher(props) {
  const response = useFetch('/v0/deities/'+props.name, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    scrollToElem(props.fragment, xs);
  }, [props.fragment, xs]);

  if (response) {
    const newLinks = [];
    response.information.forEach((elem) => {
      if (elem.props?.id && elem.props?.intoc === 'true') {
        newLinks.push({
          name: elem.props.id,
          ref: '#' + elem.props.id,
        });
      }
    });

    return (
      <div className={clsx(props.compress)}>
        <Hidden xsDown>
          <h2>{response.name}</h2>
        </Hidden>
        <div>{
          response.information?.map((elem, i) => {
            elem.props = elem.props ? {...elem.props, key: i} : {key: i};
            return j2r(React.createElement, mapper, elem);
          })}
        </div>
        <Hidden xsDown>
          <Contents
            links={newLinks}
            drawerOpen={props.drawerOpen}
            setDrawerOpen={props.setDrawerOpen}
          />
        </Hidden>
        <Hidden smUp>
          <AppBar id='xsbar' className={props.classes.xsBar}>
            <Toolbar>
              <Contents
                links={newLinks}
                drawerOpen={props.drawerOpen}
                setDrawerOpen={props.setDrawerOpen}
              />
              <h2
                style={{
                  textAlign: 'center',
                  flexGrow: 1,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                {response.name}
              </h2>
            </Toolbar>
          </AppBar>
        </Hidden>
      </div>
    );
  }
  return (
    <Redirect to='/vastestsea/deities'/>
  );
}

/**
 * Deities page
 *
 * @param {object} props the page properties
 * @return {object} JSX
 */
function Deities(props) {
  const classes = props.classes;
  const [type, setType] = React.useState({});
  const [types, setTypes] = React.useState([]);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const location = useLocation();
  const page =
    location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  // Navigation Linking
  const navLinks = [
    {name: 'Main Page', ref: '/vastestsea', to: false},
  ];
  if (page !== 'deities') {
    navLinks.push({name: 'Deities', ref: '/vastestsea/deities', to: false});
  }

  // Load deity type descriptions from database
  React.useEffect(() => {
    if (page !== 'deities') return;
    const fetchDescriptions = () => {
      fetch('/v0/deities', {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => {
          if (!res.ok) throw res;
          return res.json();
        })
        .then((json) => {
          setTypes(json);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchDescriptions();
  }, [page]);

  // Scroll to elements when window or hash are updated
  React.useEffect(() => {
    if (location.hash && location.hash !== '') {
      scrollToElem(location.hash.substring([1]), xs);
    }
  }, [location, xs]);

  // Helper function for updating the currently loaded deity type
  const updateType = async (newType) => {
    fetch('/v0/deities/'+newType, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => {
        if (!res.ok) throw res;
        return res.json();
      })
      .then((json) => {
        setType(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Force type update if loaded from URL
  React.useEffect(() => {
    if (page !== 'deities' && page !== type.name?.toLowerCase()) {
      updateType(page);
    }
  }, [page, type.name]);

  return (
    <div className={clsx(classes.flexDesktop)}>
      <Helmet>
        <title>The Vastest Sea - Deities</title>
      </Helmet>
      {xs && page !== 'deities' ? <Toolbar/> : null}
      <h1>{'*** The Vastest Sea ***'}</h1>
      <Nav
        classes={classes}
        links={navLinks}
      />
      <div className={clsx(classes.flexLeft,
        drawerOpen && !xs && classes.fitToc)}>
        <Switch>
          <Route exact path='/vastestsea/deities'>
            <h2>Deities</h2>
            <h3
              className={clsx(classes.bold, classes.italic)}
            >
              Beings of Vast Power
            </h3>
            <p>
              The Vastest Sea is home to many mortal beings, who toil through
              their lives over infinitesimal years. These beings, however, have
              divine roots. The very structure of the Vastest Sea is suffused
              with extraordinary power, in the form of Macrocosms, Fathomless,
              and Gods.
            </p>
            <div
              id='deities'
              className={
                clsx(classes.flexDesktop, classes.fullWidth, classes.flexLeft)
              }
            >
              {types.map((type) => {
                return (
                  <div
                    key={type.name}
                    className={
                      clsx(
                        classes.flexDesktop,
                        classes.fullWidth,
                        classes.flexLeft,
                      )
                    }
                  >
                    <h3
                      className={
                        clsx(
                          classes.bold,
                          classes.italic,
                          classes.header,
                        )
                      }
                    >
                      {type?.name}
                    </h3>
                    <p>
                      {type.description ?
                        type.description.map((elem, i) => {
                          elem.props = elem.props ?
                            {...elem.props, key: i} : {key: i};
                          return j2r(React.createElement, mapper, elem);
                        }) : ''}
                    </p>
                    <Link
                      className={classes.learnMore}
                      onClick={() => updateType(type.name.toLowerCase())}
                      to={'/vastestsea/deities/' + type.name.toLowerCase()}
                    >
                      Learn more.
                    </Link>
                  </div>
                );
              })}
            </div>
          </Route>
          <Route>
            <React.Suspense fallback={<LoadingAnim/>}>
              <TypeInfoFetcher
                name={page}
                fragment={location.hash.substring(1)}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                classes={classes}
              />
            </React.Suspense>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default Deities;
