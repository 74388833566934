import React from 'react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';

/**
 * Vastest Sea navigation
 * Pretty simple, data-driven method for link navigation
 *
 * @param {object} props the Nav properties
 * @return {object} JSX
 */
function Nav(props) {
  const classes = props.classes;
  return (
    <div
      className={clsx(classes.flexDesktop, classes.flexMin)}
    >
      {props.links.map((link, i) => {
        return (
          <Link
            key={link.name || i}
            to={link.ref}
          >
            {
              (link.to ? '' : '<- ') +
              link.name +
              (link.to ? ' ->' : '')
            }
          </Link>
        );
      })}
    </div>
  );
}

export default Nav;
