import React from 'react';
import clsx from 'clsx';
import {Link, Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import PortfolioEntry from './PortfolioEntry';

/**
 * Landing Page
 *
 * @param {object} props the properties
 * @return {object} JSX
 */
function Landing(props) {
  const classes = props.classes;
  return (
    <div
      className={clsx(classes.flexDesktop, classes.fullHeight)}
    >
      <Helmet>
        <title>Noah Gersh</title>
      </Helmet>
      <h1>{'<==NoahGer.sh==>'}</h1>
      <Switch>
        <Route exact path="/">
          <p><span className={classes.bold}>Contact:</span> me@noahger.sh</p>
          <div className={classes.flexRow}>
            <Link to="portfolio">Portfolio</Link>
            <p style={{marginLeft: 5, marginRight: 5}}>|</p>
            <a
              href="https://github.com/NoahJGersh"
              target="_blank"
              rel="noreferrer"
            >
              Github
            </a>
            <p style={{marginLeft: 5, marginRight: 5}}>|</p>
            <a
              href="https://gitlab.com/NoahJGersh"
              target="_blank"
              rel="noreferrer"
            >
              Gitlab
            </a>
          </div>
          <h2>Current Projects:</h2>
          <div className={classes.flexRow}>
            <a href="wormhole" target="_self">Wormhole</a>
            <p style={{marginLeft: 5, marginRight: 5}}>|</p>
            <Link to="vastestsea">The Vastest Sea</Link>
          </div>
          <p>This page is under development! Please excuse the mess.</p>
        </Route>
        <Route exact path="/wormhole">
          <h2>Redirecting...</h2>
          <meta
            httpEquiv="refresh"
            content="time; URL='https://noahger.sh/wormhole"
          />
        </Route>
        <Route exact path="/portfolio">
          <Helmet>
            <title> Noah Gersh - Portfolio</title>
          </Helmet>
          <Link to="/">{'<- Home Page'}</Link>
          <h2 style={{marginBottom: 0}}>Portfolio</h2>
          <PortfolioEntry
            name="Archivist Prime"
            description={
              'Archivist Prime is a Discord bot, written using the Discord.js '+
              'library, which serves to add Vastest Sea API utilization to ' +
              'the Vastest Sea Discord server. Currently implemented are ' +
              'definition and translation slash-commands, which use the API ' +
              'to define Fjorunskara words, or (attempt to) translate ' +
              'English words to Fjorunskara.'
            }
            source="https://gitlab.com/NoahJGersh/archivist-prime/"
          />
          <PortfolioEntry
            name="The Best Offense"
            description={
              'The Best Offense is a framework for a VR-only combat game. It '+
              'revolves around  the use of shields, and only shields, as a ' +
              `user's weapons. Shields can be bolstered, which increases ` +
              'their surface area for better protection, and additionally ' +
              'have unique activated abilities. The Best Offense is built in '+
              'UE4 with C++.'
            }
          />
          <PortfolioEntry
            name="Diligent"
            published="https://youtu.be/kncwVTFPFgM"
            description={
              'Diligent is a final project from CSE183, Web Applications, at '+
              'UCSC. It is a full-stack Slack clone, which was built in the ' +
              'span of just one week. It was pair-programmed with ' +
              'Xiangdong Che. Source code is not available for academic ' +
              'integrity purposes, but you can view the submitted demo '+
              'at the link below.'
            }
          />
          <PortfolioEntry
            name="DnD5E with Epic Characters"
            source="https://gitlab.com/NoahJGersh/dnd5e-epic-characters"
            description={
              'DnD5E with Epic Characters is a fork of the DnD5E system ' +
              'built for Foundry Virtual Tabletop software. It was built ' +
              'to accomodate a specific set of 3rd-party rules, whose ' +
              'implementation was impossible with the existing system. It ' +
              'additionally supports backwards-compatibility by ' +
              'automatically upgrading characters to use the new data ' +
              'schema. DnD5E with Epic Characters was built using pure JS.'
            }
          />
          <PortfolioEntry
            name="The Vastest Sea"
            published="vastestsea"
            source="https://gitlab.com/NoahJGersh/main-webpage/"
            description={
              'The Vastest Sea information section of my webpage, built as ' +
              'a full-stack single-page application with scalability in ' +
              'mind. Currently supports dynamic loading based on database ' +
              'content. Future plans include lexical suggestions from ' +
              'verified contributors, as well as a client-side publishing ' +
              'system to leverage the Vastest Sea RESTful API. More content ' +
              'to be added periodically! This webpage was built with a modern'+
              ' web stack, including React.js, Node.js, OpenAPI, and ' +
              'PostgreSQL.'
            }
          />
          <PortfolioEntry
            name="Wormhole"
            published="wormhole"
            source="https://github.com/NoahJGersh/wormhole"
            description={
              'A simple, WebGL-based graphics testbed. Primary focus is a ' +
              'parametrically defined tube. Currently supports vertex, ' +
              'color, size, and rudimentary shading parameters. Future plans '+
              'include bezier curve pathing, more elaborate shading, and ' +
              'audio visualization animation. The Wormhole was built ' +
              'using pure HTML, CSS, and JS. It was originally implemented ' +
              'with raw WebGL, but was later upgraded to leverage Three.JS.'
            }
          />
        </Route>
      </Switch>
    </div>
  );
}

export default Landing;
