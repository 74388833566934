import React from 'react';
import Helmet from 'react-helmet';
import clsx from 'clsx';
import {Link} from 'react-router-dom';

import Nav from '../Nav';

/**
 * Vastest Sea homepage
 *
 * @param {object} props the page properties
 * @return {object} JSX
 */
function VastestSea(props) {
  const classes = props.classes;

  return (
    <div className={classes.flexDesktop}>
      <Helmet>
        <title>The Vastest Sea - Home</title>
      </Helmet>
      <h1 className={classes.titleText}>{'<||| The Vastest Sea |||>'}</h1>
      <Nav
        classes={classes}
        links={[
          {name: 'Home Page', ref: '/', to: false},
          {name: 'Planets', ref: '/vastestsea/planets', to: true},
          {name: 'Deities', ref: '/vastestsea/deities', to: true},
          {name: 'Languages', ref: '/vastestsea/languages', to: true},
        ]}
      />
      <h2>General Information</h2>
      <p
        className={
          clsx(classes.center, classes.bold, classes.italic)
        }
      >
        {'What is the "Vastest Sea?"'}
      </p>
      <p>
        The Vastest Sea is an expansive cosmos, home to all manner of
        peoples and deities. It is so named for the sea of stars that
        appears in the night sky above any planet- the vastest sea of them
        all. Within the Sea wars are fought, alliances are forged, and
        bonds are broken. Gods may seek their own destiny, or commune with
        mortals at their leisure.
        <br/><br/>
        The Sea is best characterized by its abundance of <Link
          to="/vastestsea/planets">planets
        </Link>, many of which are the home to a multitude of cultures and
        pantheons of gods. The <Link
          to="/vastestsea/deities">deities
        </Link>, are not limited to planets, however. Gods are only one of
        three classes of immensely powerful beings- among stars with minds of
        their own, and in the shadow of those most fundamental to the Sea
        itself.
        <br/><br/>
        Some cultures may also have <Link
          to="/vastestsea/languages">languages
        </Link> of their own. Only one, a dead empire, has its developed so
        far, but there are more to come...
      </p>
      <p>Join the official Vastest Sea&nbsp;
        <a href='https://discord.gg/pV8XBhw3Pm'>
          discord server
        </a>!
      </p>
    </div>
  );
}

export default VastestSea;
